// ==========================================================================
// Base
// ==========================================================================

/* global $ */

"use strict";


//Prevent # anchors jumping page to top
$('a[href="#"]').click(function (e) {
    e.preventDefault();
});


/*
if (typeof DEVELOPMENT !== "undefined" && DEVELOPMENT) {
    $("body").addClass("debug");
}
*/
